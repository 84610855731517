<template>
    <YModal
        v-model:open="open"
        modal-class="w-full max-w-screen-sm overflow-hidden"
    >
        <div class="space-y-4 w-full text-center">
            <FaDuotoneTriangleExclamation class="text-yellow-500 dark:text-yellow-200 inline-block text-6xl pb-2" />
            <h2>{{ $t("technical_support.title") }}</h2>
            <h5 v-html="$t('technical_support.text')" />
            <a
                v-if="authStore.user"
                href="#"
                class="text-blue-500 underline text-lg"
                @click.prevent="agency"
            >
                {{ $t('agency_contact_modal.title') }}
            </a>
            <div class="flex justify-center gap-4">
                <YButton
                    href="mailto:support-future@plany.fr"
                    variant="primary"
                    @click="open = false"
                >
                    {{ $t('contact') }}
                </YButton>
                <YButton
                    variant="secondary"
                    @click="open = false"
                >
                    {{ $t('cancel') }}
                </YButton>
            </div>
        </div>
    </YModal>
</template>

<script setup lang="ts">
import { useAuthStore } from '@src/store/auth.store';

const authStore = useAuthStore();

const open = defineModel<boolean>("open", {
    default: false,
});

const emits = defineEmits(["contactAgency"]);

function agency() {
    emits('contactAgency');
    open.value = false;
}
</script>