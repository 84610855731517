<template>
  <YModal
    v-model:open="vmodelOpen"
    modal-class="!w-full !max-w-screen-sm touch:!max-w-full space-y-4 text-center"
  >
    <FaDuotoneUserSlash class="text-5xl text-error-content dark:text-error" />
    <h2>
      {{ $t("delete_account.modal_title") }}
    </h2>
    <h3>
      {{ $t("delete_account.modal_text") }}
    </h3>
    <YAlert variant="error">
      <span v-html="$t('delete_account.modal_alert')" />
    </YAlert>
    <FormKit
      type="form"
      form-class="w-full max-w-screen-2xs space-y-4"
      @submit="submit"
    >
      <FormKit
        id="email"
        type="email"
        name="email"
        inner-class="w-full bg-base-200 shadow shadow-base-300/50 !opacity-100"
        message-class="w-full text-center"
        validation="required"
        :validation-label="$t('email_address')"
        :placeholder="$t('email_address')"
        :disabled="!!email"
        @vue:mounted="onEmailMounted"
      />
      <template #actions="{ state }">
        <YButton
          variant="secondary"
          :loading="state.loading"
          :disabled="state.loading || !state.valid"
        >{{ $t("delete_account.modal_button") }}</YButton>
      </template>
    </FormKit>
  </YModal>
</template>

<script setup lang="ts">
import { ModalAlertBus } from "@lib/plugins/ModalAlertPlugin";
import AuthService from "@src/services/auth.service";
import { HTTPError } from "ky";

const { t } = useI18n();

const props = defineProps<{ email?: string; }>();

const vmodelOpen = defineModel<boolean>("open", {
  default: false,
});

async function submit(data: { email: string }) {
  try {
    await AuthService.deleteLink(data.email);
    vmodelOpen.value = false;

    ModalAlertBus.modalAlert({
      type: "success",
      title: t("delete_account.modal_success_title"),
      text: t("delete_account.modal_success_text"),
      modalClass: "!max-w-screen-sm",
    });
  } catch (e) {
    if (e instanceof HTTPError) {
      vmodelOpen.value = false;
      const data = await e.response.json();
      switch (e.response.status) {
        case 429:
          ModalAlertBus.modalAlert({
            type: "error",
            title: t("error"),
            text: t("delete_account.modal_error_text"),
            modalClass: "!max-w-screen-sm",
          });
          return;
        case 409:
          ModalAlertBus.modalAlert({
            type: "error",
            title: t("error"),
            text: data.errors.message,
            modalClass: "!max-w-screen-sm",
          });
          return;
      }
    }

    throw e;
  }
}

function onEmailMounted() {
  const node = getNode("email");
  if (props.email && node) node.input(props.email);
}

watch(() => props.email, (email) => {
  if (email) {
    const node = getNode("email");
    node?.input(props.email);
  }
})
</script>
