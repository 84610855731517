/* eslint-disable import/no-namespace */
import * as fr from "./lang/fr";
import * as en from "./lang/en";
import * as es from "./lang/es";
import * as it from "./lang/it";
import * as de from "./lang/de";

import DatePickerSelect from "@src/components/form/DatePickerSelect.vue";
import ButtonsSelect from "@src/components/form/ButtonsSelect.vue";

import text from "./form/text";
import checkbox from "./form/checkbox";
import file from "./form/file";
import range from "./form/range";
import textarea from "./form/textarea";
import select from "./form/select";

import phone from "./rules/phone";
import hasLowerAndUppercase from "./rules/hasLowerAndUppercase";
import hasNumber from "./rules/hasNumber";
import hasSpecial from "./rules/hasSpecial";
import mimes from "./rules/mimes";
import integer from "./rules/integer";

import requiredAsteriskPlugin from "./plugins/requiredAsterisk";
import fileSize from "./rules/fileSize";
import SpokenLanguages from "@src/components/form/SpokenLanguages.vue";
import DatePicker from "@src/components/form/DatePicker.vue";
import datePickerSelectFeature from "./plugins/datepickerSelect";
import FormSelect from "@src/components/form/FormSelect.vue";

const pro = createProPlugin(import.meta.env.VITE_FORMKIT_PRO_API_KEY, inputs)

export default defaultConfig({
  config: {
    classes: generateClasses({
      text,
      email: text,
      password: text,
      select,
      planySelect: select,
      datepicker: text,
      datepickerSelect: text,
      number: text,
      range,
      file,
      checkbox,
      textarea,
    }),
  },
  locales: {
    fr,
    en,
    de,
    it,
    es,
  },
  locale: "fr",
  inputs: {
    planySelect: createInput(FormSelect, {
      props: ["options"],
    }),
    datepicker: createInput(DatePicker, {
      props: ["mobiscrollOptions", "multiple", "dateFormat", "deletable"],
    }),
    datepickerSelect: createInput(DatePickerSelect, {
      props: [
        "dayPlaceholder",
        "monthPlaceholder",
        "yearPlaceholder",
        "dayAttrs",
        "monthAttrs",
        "yearAttrs",
      ],
      features: [datePickerSelectFeature],
    }),
    buttonsSelect: createInput(ButtonsSelect, {
      props: ["options"],
    }),
    spokenLanguages: createInput(SpokenLanguages)
  },
  rules: {
    phone,
    hasLowerAndUppercase,
    hasNumber,
    hasSpecial,
    mimes,
    integer,
    fileSize,
  },
  plugins: [requiredAsteriskPlugin, pro],
});
