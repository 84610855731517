/* eslint-disable eqeqeq */
import { FormKitValidationMessages } from "@formkit/validation";

/**
 * Here we can import additional helper functions to assist in formatting our
 * language. Feel free to add additional helper methods to libs/formats if it
 * assists in creating good validation messages for your locale.
 */
import {
  sentence as s,
  list,
  date,
  order,
  FormKitLocaleMessages,
} from "@formkit/i18n";
import mime from "mime";
import { uploadMaxFilesize } from "@config/media";

/**
 * Standard language for interface features.
 * @public
 */
export const ui: FormKitLocaleMessages = {
  /**
   * Shown on buttons for adding new items.
   */
  add: "Ajouter",
  /**
   * Shown when a button to remove items is visible.
   */
  remove: "Supprimer",
  /**
   * Shown when there are multiple items to remove at the same time.
   */
  removeAll: "Enlever tout",
  /**
   * Shown when all fields are not filled out correctly.
   */
  incomplete: "Désolé, tous les champs ne sont pas remplis correctement.",
  /**
   * Shown in a button inside a form to submit the form.
   */
  submit: "Valider",
  /**
   * Shown when no files are selected.
   */
  noFiles: "Rechercher un fichier",
};

/**
 * These are all the possible strings that pertain to validation messages.
 * @public
 */
export const validation: FormKitValidationMessages = {
  /**
   * The value is not an accepted value.
   * @see {@link https://docs.formkit.com/essentials/validation#accepted}
   */
  accepted({ name }): string {
    /* <i18n case="Shown when the user-provided value is not a valid 'accepted' value."> */
    return `Veuillez accepter le ${name}.`;
    /* </i18n> */
  },

  /**
   * The date is not after
   * @see {@link https://docs.formkit.com/essentials/validation#date-after}
   */
  date_after({ name, args }) {
    if (Array.isArray(args) && args.length) {
      /* <i18n case="Shown when the user-provided date is not after the date supplied to the rule."> */
      return `${s(name)} doit être postérieure au ${date(args[0])}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided date is not after today's date, since no date was supplied to the rule."> */
    return `${s(name)} doit être dans le futur.`;
    /* </i18n> */
  },

  /**
   * The value is not a letter.
   * @see {@link https://docs.formkit.com/essentials/validation#alpha}
   */
  alpha({ name }) {
    /* <i18n case="Shown when the user-provided value contains non-alphabetical characters."> */
    return `${s(name)} ne peut contenir que des caractères alphabétiques.`;
    /* </i18n> */
  },

  /**
   * The value is not alphanumeric
   * @see {@link https://docs.formkit.com/essentials/validation#alphanumeric}
   */
  alphanumeric({ name }) {
    /* <i18n case="Shown when the user-provided value contains non-alphanumeric characters."> */
    return `${s(name)} ne peut contenir que des lettres et des chiffres.`;
    /* </i18n> */
  },

  /**
   * The value is not letter and/or spaces
   * @see {@link https://docs.formkit.com/essentials/validation#alpha-spaces}
   */
  alpha_spaces({ name }) {
    /* <i18n case="Shown when the user-provided value contains non-alphabetical and non-space characters."> */
    return `${s(name)} ne peuvent contenir que des lettres et des espaces.`;
    /* </i18n> */
  },

  /**
   * The date is not before
   * @see {@link https://docs.formkit.com/essentials/validation#date-before}
   */
  date_before({ name, args }) {
    if (Array.isArray(args) && args.length) {
      /* <i18n case="Shown when the user-provided date is not before the date supplied to the rule."> */
      return `${s(name)} doit être antérieure au ${date(args[0])}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided date is not before today's date, since no date was supplied to the rule."> */
    return `${s(name)} doit être dans le passé.`;
    /* </i18n> */
  },

  /**
   * The value is not between two numbers
   * @see {@link https://docs.formkit.com/essentials/validation#between}
   */
  between({ name, args }) {
    if (isNaN(args[0]) || isNaN(args[1])) {
      /* <i18n case="Shown when any of the arguments supplied to the rule were not a number."> */
      return `Ce champ a été configuré de manière incorrecte et ne peut pas être soumis.`;
      /* </i18n> */
    }
    const [a, b] = order(args[0], args[1]);
    /* <i18n case="Shown when the user-provided value is not between two numbers."> */
    return `${s(name)} doit être comprise entre ${a} et ${b}.`;
    /* </i18n> */
  },

  /**
   * The confirmation field does not match
   * @see {@link https://docs.formkit.com/essentials/validation#confirm}
   */
  confirm({ name }) {
    /* <i18n case="Shown when the user-provided value does not equal the value of the matched input."> */
    return `${s(name)} ne correspond pas.`;
    /* </i18n> */
  },

  /**
   * The value is not a valid date
   * @see {@link https://docs.formkit.com/essentials/validation#date-format}
   */
  date_format({ name, args }) {
    if (Array.isArray(args) && args.length) {
      /* <i18n case="Shown when the user-provided date does not satisfy the date format supplied to the rule."> */
      return `${s(
        name
      )} n'est pas une date valide, veuillez utiliser le format ${args[0]}`;
      /* </i18n> */
    }
    /* <i18n case="Shown when no date argument was supplied to the rule."> */
    return "Ce champ a été configuré de manière incorrecte et ne peut pas être soumis.";
    /* </i18n> */
  },

  /**
   * Is not within expected date range
   * @see {@link https://docs.formkit.com/essentials/validation#date-between}
   */
  date_between({ name, args }) {
    /* <i18n case="Shown when the user-provided date is not between the start and end dates supplied to the rule. "> */
    return `${s(name)} doit être comprise entre ${date(args[0])} et ${date(
      args[1]
    )}`;
    /* </i18n> */
  },

  /**
   * Shown when the user-provided value is not a valid email address.
   * @see {@link https://docs.formkit.com/essentials/validation#email}
   */
  email: "Veuillez saisir une adresse email valide.",

  /**
   * Does not end with the specified value
   * @see {@link https://docs.formkit.com/essentials/validation#ends-with}
   */
  ends_with({ name, args }) {
    /* <i18n case="Shown when the user-provided value does not end with the substring supplied to the rule."> */
    return `${s(name)} ne se termine pas par ${list(args)}.`;
    /* </i18n> */
  },

  /**
   * Is not an allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#is}
   */
  is({ name }) {
    /* <i18n case="Shown when the user-provided value is not one of the values supplied to the rule."> */
    return `${s(name)} n'est pas une valeur autorisée.`;
    /* </i18n> */
  },

  /**
   * Does not match specified length
   * @see {@link https://docs.formkit.com/essentials/validation#length}
   */
  length({ name, args: [first = 0, second = Infinity] }) {
    const min = Number(first) <= Number(second) ? first : second;
    const max = Number(second) >= Number(first) ? second : first;
    if (min == 1 && max === Infinity) {
      /* <i18n case="Shown when the length of the user-provided value is not at least one character."> */
      return `Le champ ${s(name)} doit comporter au moins un caractère.`;
      /* </i18n> */
    }
    if (min == 0 && max) {
      /* <i18n case="Shown when first argument supplied to the rule is 0, and the user-provided value is longer than the max (the 2nd argument) supplied to the rule."> */
      return `${s(name)} doit être inférieur ou égal à ${max} caractères.`;
      /* </i18n> */
    }
    if (min && max === Infinity) {
      /* <i18n case="Shown when the length of the user-provided value is less than the minimum supplied to the rule and there is no maximum supplied to the rule."> */
      return `${s(name)} doit être supérieur ou égal à ${min} caractères.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the length of the user-provided value is between the two lengths supplied to the rule."> */
    return `${s(name)} doit être comprise entre ${min} et ${max} caractères.`;
    /* </i18n> */
  },

  /**
   * Value is not a match
   * @see {@link https://docs.formkit.com/essentials/validation#matches}
   */
  matches({ name }) {
    /* <i18n case="Shown when the user-provided value does not match any of the values or RegExp patterns supplied to the rule. "> */
    return `${s(name)} n'est pas une valeur autorisée.`;
    /* </i18n> */
  },

  /**
   * Exceeds maximum allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#max}
   */
  max({ name, node: { value }, args }) {
    if (Array.isArray(value)) {
      /* <i18n case="Shown when the length of the array of user-provided values is longer than the max supplied to the rule."> */
      return `Ne peut pas avoir plus de ${args[0]} ${name}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided value is greater than the maximum number supplied to the rule."> */
    return `${s(name)} doit être inférieur ou égal à ${args[0]}.`;
    /* </i18n> */
  },

  /**
   * The (field-level) value does not match specified mime type
   * @see {@link https://docs.formkit.com/essentials/validation#mime}
   */
  mime({ name, args }) {
    if (!args[0]) {
      /* <i18n case="Shown when no file formats were supplied to the rule."> */
      return "Aucun format de fichier n’est autorisé";
      /* </i18n> */
    }
    /* <i18n case="Shown when the mime type of user-provided file does not match any mime types supplied to the rule."> */
    return `${s(name)} doit être du type: ${args[0]}`;
    /* </i18n> */
  },

  /**
   * Does not fulfill minimum allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#min}
   */
  min({ name, node: { value }, args }) {
    if (Array.isArray(value)) {
      /* <i18n case="Shown when the length of the array of user-provided values is shorter than the min supplied to the rule."> */
      return `Ne peut pas avoir moins de ${args[0]} ${name}.`;
      /* </i18n> */
    }
    /* <i18n case="Shown when the user-provided value is less than the minimum number supplied to the rule."> */
    return `${s(name)} doit être au moins de ${args[0]}.`;
    /* </i18n> */
  },

  /**
   * Is not an allowed value
   * @see {@link https://docs.formkit.com/essentials/validation#not}
   */
  not({ name, node: { value } }) {
    /* <i18n case="Shown when the user-provided value matches one of the values supplied to (and thus disallowed by) the rule."> */
    return `“${value}” n'est pas un ${name} autorisé.`;
    /* </i18n> */
  },

  /**
   *  Is not a number
   * @see {@link https://docs.formkit.com/essentials/validation#number}
   */
  number({ name }) {
    /* <i18n case="Shown when the user-provided value is not a number."> */
    return `Le champ ${s(name)} doit être un nombre.`;
    /* </i18n> */
  },

  /**
   * Required field.
   * @see {@link https://docs.formkit.com/essentials/validation#required}
   */
  required({ name }) {
    /* <i18n case="Shown when a user does not provide a value to a required input."> */
    return `Le champ ${s(name)} est requis.`;
    /* </i18n> */
  },

  /**
   * Does not start with specified value
   * @see {@link https://docs.formkit.com/essentials/validation#starts-with}
   */
  starts_with({ name, args }) {
    /* <i18n case="Shown when the user-provided value does not start with the substring supplied to the rule."> */
    return `${s(name)} ne commence pas par ${list(args)}.`;
    /* </i18n> */
  },

  /**
   * Is not a url
   * @see {@link https://docs.formkit.com/essentials/validation#url}
   */
  url() {
    /* <i18n case="Shown when the user-provided value is not a valid url."> */
    return `Veuillez saisir une url valide.`;
    /* </i18n> */
  },

  phone() {
    /* <i18n case="Shown when a user does not provide a valid phone number."> */
    return `Le numéro de téléphone n'est pas valide.`;
    /* </i18n> */
  },

  hasLowerAndUppercase({ name }) {
    /* <i18n case="Shown when a field doesn't have lowercase and uppercase characters."> */
    return `Le champ ${s(
      name
    )} doit contenir au moins une lettre en minuscule et une lettre en majuscule`;
    /* </i18n> */
  },

  hasNumber({ name }) {
    /* <i18n case="Shown when a field doesn't have number"> */
    return `Le champ ${s(name)} doit contenir au moins un chiffre`;
    /* </i18n> */
  },

  hasSpecial({ name }) {
    /* <i18n case="Shown when a field doesn't have special character"> */
    return `Le champ ${s(name)} doit contenir au moins un caractère spécial`;
    /* </i18n> */
  },

  mimes({ args, name, node }) {
    const isMultiple = !!node.props.attrs?.multiple;
    const accept = Array.from((args[0] ?? []) as string[])
      .map((value: string) => mime.getExtension(value) ?? value)
      .join(", ");
    return isMultiple
      ? `Les fichiers fournis dans le champ ${s(
          name
        )} doivent être de type : ${accept}`
      : `Le fichier fourni dans le champ ${s(
          name
        )} doit être de type : ${accept}`;
  },

  integer({ name }) {
    return `Le champ ${s(name)} doit être un nombre entier`;
  },

  fileSize({ args, name, node }) {
    const isMultiple = !!node.props.attrs?.multiple;
    const maxFileSize = args[0] ?? uploadMaxFilesize;
    return isMultiple
      ? `Les fichiers fournis dans le champ ${s(
          name
        )} sont trop volumineux. (Maximum ${maxFileSize})`
      : `Le fichiers fourni dans le champ ${s(
          name
        )} est trop volumineux. (Maximum ${maxFileSize})`;
  },
};
