import { Dayjs } from "dayjs";
import { Gender, SimpleData } from "../global";

export interface ThumbPicture<T extends number> {
  path: string;
  thumbnails: Record<T, string>;
}

export interface Requirements {
  documents?: SimpleData<string>[];
  fields?: SimpleData<string>[];
}

export type TimeClockingType = "clock_in" | "clock_out";

export interface TimeClocking {
  expected_at: string;
  mission_name: string;
  location: {
    lat: string;
    lng: string;
    name: string;
  };
  type: TimeClockingType;
}

export interface Alert {
  id: string;
  data: any;
}

interface Address {
  region: string | null;
  country_code: string;
  type: string;
  name: string;
  street: string;
  additional_details: string;
  city: string;
  postcode: string;
  geo_lat: string;
  geo_lng: string;
}

export interface EmergencyContact {
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface UserData {
  wizard_type: string | null;
  uuid: string;
  email: string;
  address: Address | null;
  alerts: Alert[] | undefined;
  nationality: string;
  birthdate: string;
  birth_city: string;
  birth_country: string;
  emergency_contacts: EmergencyContact;
  first_name: string;
  last_name: string;
  gender: 0 | 1;
  profile_picture: ThumbPicture<64 | 128 | 256> | null;
  pictures: Array<ThumbPicture<350>>;
  lang: string;
  origin_agency: string | null;
  origin_agency_iso: string;
  requirements?: Requirements;
  time_clocking?: TimeClocking;
  has_emails: boolean;
  subscribe_to_job_base: boolean;
  has_marketing_emails: boolean;
  impersonate: boolean;
  mobile_phone: string;
  "2fa_type": "TOTP" | "EMAIL" | null;
  activity_sectors?: number[];
}

export type MeResponse = { data: UserData };

export interface UpdateProfileData {
  has_emails: boolean;
  subscribe_to_job_base: boolean;
  email: string;
  lang: string;
  first_name: string;
  last_name: string;
  gender: 0 | 1;
  birthdate: string;
  birth_city: string;
  birth_country: number | null;
  mobile_phone: string;
  has_marketing_emails: boolean;
}

export class User {
  private _uuid: string;

  public email: string;
  public profile_picture: ThumbPicture<64 | 128 | 256> | null;
  public pictures: Array<ThumbPicture<350>>;
  public first_name: string;
  public address: Address | null;
  public last_name: string;
  public gender: 0 | 1;
  public lang: string;
  public nationality: string;
  public birthdate: Dayjs;
  public birth_city: string;
  public birth_country: number | null;
  public emergency_contacts: EmergencyContact;
  public requirements: Requirements | undefined;
  public time_clocking: TimeClocking | undefined;
  public alerts: Alert[];
  public origin_agency?: string;
  public origin_agency_iso: string;
  public has_emails: boolean;
  public subscribe_to_job_base: boolean;
  public has_marketing_emails: boolean;
  public mobile_phone: string;
  public impersonate: boolean;
  public wizard_type: string | null;
  public "2fa_type": "TOTP" | "EMAIL" | null;
  public activity_sectors?: number[];

  constructor(data: UserData) {
    this._uuid = data.uuid;
    this.email = data.email;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.gender = data.gender;
    this.address = data.address;
    this.profile_picture = data.profile_picture;
    this.pictures = data.pictures;
    this.profile_picture = data.profile_picture;
    this.pictures = data.pictures;
    this.lang = data.lang;
    this.nationality = data.nationality;
    this.birthdate = dayjs(data.birthdate, "YYYY-MM-DD");
    this.birth_city = data.birth_city;
    this.birth_country = data.birth_country
      ? parseInt(data.birth_country)
      : null;
    this.emergency_contacts = data.emergency_contacts;
    this.requirements = data.requirements;
    this.time_clocking = data.time_clocking;
    this.alerts = data.alerts ?? [];
    this.origin_agency = data.origin_agency ?? undefined;
    this.origin_agency_iso = data.origin_agency_iso;
    this.has_emails = data.has_emails;
    this.has_marketing_emails = data.has_marketing_emails;
    this.subscribe_to_job_base = data.subscribe_to_job_base;
    this.mobile_phone = data.mobile_phone;
    this.wizard_type = data.wizard_type;
    this.impersonate = data.impersonate;
    this["2fa_type"] = data["2fa_type"];
    this.activity_sectors = data.activity_sectors;
  }

  public get uuid() {
    return this._uuid;
  }

  public get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  public get age() {
    return dayjs().diff(this.birthdate, "year");
  }

  public get hasRequirements() {
    return !!(
      this.requirements &&
      (this.requirements.documents || this.requirements.fields)
    );
  }

  public get updateProfileData(): UpdateProfileData {
    return {
      has_emails: this.has_emails,
      subscribe_to_job_base: this.subscribe_to_job_base,
      email: this.email,
      lang: this.lang,
      first_name: this.first_name,
      last_name: this.last_name,
      gender: this.gender,
      birthdate: this.birthdate.format("YYYY-MM-DD"),
      birth_city: this.birth_city,
      birth_country: this.birth_country,
      mobile_phone: this.mobile_phone,
      has_marketing_emails: this.has_marketing_emails,
    };
  }

  public hasAlerts(...ids: string[]) {
    return !!this.alerts.find((alert) => ids.includes(alert.id));
  }

  public getAlert(id: string) {
    return this.alerts.find((alert) => alert.id === id);
  }
}

interface PersonalBase {
  first_name?: string;
  last_name?: string;
  lang?: string;
  birthdate?: Date | string;
  mobile_phone?: string;
}

export interface LegacyAccount extends PersonalBase {
  gender?: 0 | 1;
  email?: string;
}

export interface Personal extends PersonalBase {
  gender?: Gender;
}

export interface Identification {
  email?: string;
  password?: string;
  password_confirmation?: string;
}

export interface CguPrivacy {
  has_approved_c_g_u?: boolean;
  has_approved_privacy?: boolean;
  has_marketing_emails?: boolean;
}

export type RegisterData = Omit<Personal, "gender"> &
  Identification &
  CguPrivacy & {
    agency?: string;
    gender?: number;
    retrieval_code?: string;
  };

export interface RegisterResponse {
  data?: {
    uuid: string;
  };
}

export interface LoginData {
  email: string;
  password: string;
}

export interface ResendValidateEmailResponse {
  data: {
    message: string;
  };
}

export interface UpdatePasswordData {
  password?: string;
  new_password?: string;
}

export interface LostPasswordData {
  email: string;
}

export interface ResetPasswordHash {
  token: string;
  email: string;
}

export interface ResetPasswordData {
  password: string;
  token?: string;
}

export interface ProfilePicture {
  new: boolean;
  delete?: boolean;
  fileName?: string;
  path?: string;
  thumb?: string;
  file?: File;
}

export interface SetProfilePictureResponse {
  data?: {
    file: string;
  };
}

export interface SetPicturesResponse {
  data?: {
    files: string[];
  };
}

export interface SubscriptionAgency extends SimpleData<string> {
  email: string;
  registered_since: Dayjs;
  is_main_agency: boolean;
  is_muted: boolean;
  is_deleted: boolean;
  logo: string;
  background: string;
  has_custom_criteria?: boolean;
}

export interface SubscriptionAgencyVacations extends SubscriptionAgency {
  supports_vacations_option: boolean;
  supported_vacations_types: SimpleData<number>[];
}

export interface GetSubscriptionsResponse<T = SubscriptionAgency> {
  data?: T[];
}

export interface AcceptTimeClockingData {
  geolocation: {
    coords: {
      speed?: string | null;
      heading?: string | null;
      accuracy?: string | null;
      altitude?: string | null;
      latitude?: string | null;
      longitude?: string | null;
      altitude_accuracy?: string | null;
    };
    timestamp?: string;
  };
  type: TimeClockingType;
}
