//./plugins/posthog.js
import posthog from "posthog-js";
import type { App } from "vue";

export default {
  install(app: App) {
    app.config.globalProperties.$posthog = posthog.init(
      import.meta.env.VITE_POSTHOG_API_KEY,
      {
        api_host: "https://eu.i.posthog.com",
      }
    );
  },
};
