<template>
  <input
    :id="context.id"
    ref="input"
    type="text"
    :name="context.node.name"
    :disabled="context.disabled"
    :class="
      context.multiple
        ? '-z-1 absolute inset-0 opacity-0'
        : context.classes.input
    "
    readonly
    v-bind="context.attrs"
    @blur.prevent="onBlur"
  />
  <tag-input
    v-if="context.multiple"
    :for="context.id"
    :class="context.classes.input"
    :values="valueText"
    @remove="removeItem"
  />
  <a
    v-if="canDelete"
    class="text-base-content absolute right-1.5 text-sm p-1 top-1/2 -translate-y-1/2 rounded-md bg-base-300 bg-opacity-50 hover:text-error-content dark:hover:text-error"
    href="#"
    @click.prevent.stop="onDelete">
    <FaLightXmark />
  </a>
</template>

<script setup lang="ts">
import { useMobiscroll } from "@lib/composables/useMobiscroll";
import {
  MbscDatepickerOptions,
  Datepicker,
  datepicker,
} from "@mobiscroll/javascript";
import dayjs from "@src/utils/dayjs";
import { computed, ref, toRef, watch } from 'vue';

interface Handlers {
  blur?: () => void;
}

interface Context {
  id: string;
  node: {
    input: (val: any) => any;
    name: string;
  };
  disabled: boolean;
  _value: any;
  value: any;
  classes: any;
  attrs: Record<string, any>;
  options: any[];
  mobiscrollOptions?: MbscDatepickerOptions;
  multiple?: boolean;
  dateFormat?: string;
  deletable?: boolean;
  monthYearPicker?: boolean;
  handlers?: Handlers;
}

interface Props {
  context: Context;
}

const props = defineProps<Props>();

const input = ref<HTMLInputElement>();

const context = toRef(props, "context");

const mobiscrollOptions = computed(
  (): MbscDatepickerOptions => ({
    dateFormat: context.value.monthYearPicker
      ? "MM/YYYY"
      : context.value.dateFormat ?? "D MMMM YYYY",
    selectMultiple: context.value.multiple,
    dateWheels: context.value.monthYearPicker ? 'mm yy' : undefined,
    ...context.value.mobiscrollOptions,
  })
);

const getInstance = useMobiscroll<
  MbscDatepickerOptions,
  Datepicker,
  typeof datepicker
>(
  input,
  datepicker,
  {
    touchUi: "auto",
    showOnFocus: true,
    onInit(args, instance: Datepicker) {
      instance.setVal(props.context._value);
    },
    onOpen(args, instance: Datepicker) {
      instance.setVal(props.context._value);
    },
    onChange({ value }) {
      props.context.node.input(value);
    },
  },
  mobiscrollOptions
);

function removeItem(key: number) {
  if (Array.isArray(props.context._value)) {
    const _value = props.context._value;
    _value.splice(key, 1);

    getInstance()?.setVal(_value);
  }
}

function onDelete() {
  if (props.context.multiple) {
    getInstance()?.setVal([]);
  } else {
    getInstance()?.setVal(null);
  }
}

function onBlur() {
  if (
    context.value.handlers &&
    typeof context.value.handlers.blur === 'function'
  ) {
    context.value.handlers.blur();
  }
}

const canDelete = computed(() => {
  if (props.context.deletable) {
    if (props.context.multiple) {
      return (
        Array.isArray(props.context._value) && props.context._value.length > 0
      );
    }
    return !!props.context._value;
  }
  return false;
});

const valueText = computed(() => {
  if (Array.isArray(props.context._value)) {
    return props.context._value.map((value) =>
      dayjs(value).format(context.value.dateFormat ?? "D MMMM YYYY")
    );
  } else if (props.context._value) {
    return [dayjs(props.context._value).format(context.value.dateFormat ?? "D MMMM YYYY")];
  }
  return [];
});

watch(
  () => props.context._value,
  (value, oldValue) => {
    if (JSON.stringify(value) !== JSON.stringify(oldValue))
      getInstance()?.setVal(value);
  },
  { deep: true }
);
</script>
