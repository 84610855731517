const translations = {
  en: {
    consentModal: {
      title: "We use cookies",
      description: `We use different categories of cookies, including 
                  Google Analytics 4, to better understand how you 
                  interact with our site. You can accept all cookies 
                  or customize your preferences.`,
      acceptAllBtn: "Accept all",
      acceptNecessaryBtn: "Reject all",
      showPreferencesBtn: "Customize",
      footer: `
        <a href="/privacy" target="_blank">Privacy Policy</a>
        <a href="/legal" target="_blank">Terms and Conditions</a>
       `,
    },

    preferencesModal: {
      title: "Cookie Settings",
      acceptAllBtn: "Accept all",
      acceptNecessaryBtn: "Reject all",
      savePreferencesBtn: "Save my choices",
      closeIconLabel: "Close",
      serviceCounterLabel: "Services",
      sections: [
        {
          title: "Cookie Usage",
          description: `We use cookies to improve your browsing experience, 
               analyze site usage and facilitate certain features. 
               Choose below which types of cookies you wish to accept.`,
        },
        {
          title:
            'Strictly Necessary Cookies <span class="pm__badge">Always enabled</span>',
          description: `These cookies are essential for the site to function and cannot be 
               disabled. They ensure security and proper display of pages.`,
          linkedCategory: "necessary",
        },
        {
          title: "Functionality Cookies",
          description: `They allow for improved personalization and advanced 
               features. Without them, some parts of the site may not 
               function as intended.`,
          linkedCategory: "functionality",
        },
        {
          title: "Analytics Cookies",
          description: `We mainly use Google Analytics 4 to understand 
               how you interact with our site. These cookies help us 
               collect anonymous statistics to improve our content 
               and your experience.`,
          linkedCategory: "analytics",
        },
        {
          title: "More Information",
          description: `For any questions regarding our cookie policy or 
               to exercise your rights, please <a class="cc__link" href="mailto:support-candidats@plany.fr">contact us</a>.`,
        },
      ],
    },
  },
  fr: {
    consentModal: {
      title: "Nous utilisons des cookies 🍪",
      description: `Pour optimiser votre expérience et améliorer nos services, nous utilisons de manière anonyme des outils d'analyse qui nécessitent l'utilisation de cookies. Vous pouvez choisir d'accepter tous les cookies ou personnaliser vos préférences pour garder le contrôle de vos données.`,
      acceptAllBtn: "Accepter",
      acceptNecessaryBtn: "Refuser",
      showPreferencesBtn: "Personnaliser",
      footer: `
       <a href="/privacy" target="_blank">Politique de confidentialité</a>
       <a href="/legal" target="_blank">Termes et conditions</a>
      `,
    },

    preferencesModal: {
      title: "Paramètres des cookies",
      acceptAllBtn: "Accepter",
      acceptNecessaryBtn: "Refuser",
      savePreferencesBtn: "Enregistrer mes choix",
      closeIconLabel: "Fermer",
      serviceCounterLabel: "Services",
      sections: [
        {
          title: "Utilisation des cookies",
          description: `Nous utilisons des cookies pour améliorer votre expérience de navigation, 
                  analyser l'utilisation de notre site et faciliter certaines fonctionnalités. 
                  Choisissez ci-dessous quels types de cookies vous souhaitez accepter.`,
        },
        {
          title:
            'Cookies Strictement Nécessaires <span class="pm__badge">Toujours activés</span>',
          description: `Ces cookies sont essentiels au fonctionnement du site et ne peuvent pas 
                  être désactivés. Ils assurent notamment la sécurité et le bon affichage 
                  des pages.`,
          linkedCategory: "necessary",
        },
        {
          title: "Cookies de Fonctionnalités",
          description: `Ils permettent d'améliorer la personnalisation et de proposer 
                  des fonctionnalités avancées. Sans eux, certaines parties du site 
                  pourraient ne pas fonctionner comme prévu.`,
          linkedCategory: "functionality",
        },
        {
          title: "Cookies Analytiques",
          description: `Nous utilisons principalement Google Analytics 4 pour comprendre 
                  comment vous interagissez avec notre site. Ces cookies nous aident 
                  à collecter des statistiques anonymes afin d'améliorer nos contenus 
                  et votre expérience.`,
          linkedCategory: "analytics",
        },
        {
          title: "Plus d'informations",
          description: `Pour toute question concernant notre politique en matière de cookies ou 
                  pour exercer vos droits, veuillez <a class="cc__link" href="mailto:support-candidats@plany.fr">nous contacter</a>.`,
        },
      ],
    },
  },
};

export default translations;