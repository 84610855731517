import {
  base as inputBase,
  invalid as inputInvalid,
  errors as inputErrors,
} from "./base";

const base: Record<string, string> = {
  ...inputBase,
  inner:
    "$reset relative input h-auto min-h-[3rem] input-primary flex items-center px-0 py-1 border-transparent focus-within:border-primary-color-600 dark:focus-within:border-primary formkit-disabled:opacity-50 formkit-disabled:pointer-events-none",
  input: "$reset appearance-none opacity-0 absolute inset-0 cursor-pointer z-2",
  noFiles: "$reset px-3 font-medium text-placeholder whitespace-nowrap flex items-center gap-3",
  fileList: "$reset w-full text-ellipsis",
  fileItem: "$reset pl-3 flex items-center gap-3",
  fileName: "$reset whitespace-nowrap	text-ellipsis	overflow-hidden",
  fileRemove: `$reset flex-shrink-0 ml-auto flex gap-3 items-center relative bg-neutral text-neutral-content px-3 py-1 mr-2 transitioned lg:whitespace-nowrap z-3 rounded-lg
    hover:bg-error hover:text-error-content`,
};

const invalid: Record<keyof typeof base, string> = {
  ...inputInvalid,
  inner:
    "formkit-invalid:input-error formkit-invalid:border-error-content formkit-invalid:dark:border-error",
};

const errors: Record<keyof typeof base, string> = {
  ...inputErrors,
  inner:
    "formkit-errors:input-error formkit-errors:border-error-content formkit-errors:dark:border-error",
};

Object.entries(base).forEach((value: [keyof typeof base, string]) => {
  const key = value[0];
  const val = value[1];

  base[key] += `${val} ${invalid[key]} ${errors[key]}`;
});

export default base;