import GenericService from "@src/services/generic.service";
import {
  Generic,
  JobsGeneric,
  Region,
} from "@src/types/services/generic.service";
import { Ref, MaybeRef } from "vue";
import { createInstance } from "localforage";
import { useAuthStore } from "./auth.store";

interface GenericStateReturn<T> {
  data: Ref<T | undefined>;
  loading: Ref<boolean>;
  fetch: (force?: boolean) => Promise<T | undefined>;
}

let storage: LocalForage | undefined;

function useGenericState<T extends []>(
  name: string,
  base?: string,
  store: boolean = true,
  params?: MaybeRef<Record<string, string>>
): GenericStateReturn<T> {
  const data = ref<T>();
  const loading = ref(false);
  const promise = ref<Promise<T>>();

  async function fetch(force = false) {
    if (!data.value || force) {
      loading.value = true;

      let _data: T | undefined;

      if (store) {
        const storageData = await storage
          ?.getItem<{ createdAt: number; data: T }>(name)
          .catch(() => {
            //
          });
        _data = storageData?.data;
      }

      if (!_data) {
        if (!promise.value) {
          promise.value = GenericService.fetchGeneric<T>(
            name,
            base,
            unref(params)
          );
        }
        const response = await promise.value;
        promise.value = undefined;

        if (store) {
          storage
            ?.setItem(name, {
              createdAt: Date.now(),
              data: response,
            })
            .catch(() => {
              //
            });
        }

        _data = response;
      }

      data.value = _data;
      loading.value = false;

      return _data;
    }
    return data.value;
  }

  return {
    data,
    loading,
    fetch,
  };
}

export const useGenericStore = defineStore("generic", () => {
  const { locale } = useI18n();
  const authStore = useAuthStore();
  const studyParams = computed((): Record<string, string> => {
    if (authStore.user) {
      return {
        "filter[country]": authStore.user.origin_agency_iso,
      };
    }

    return {};
  });

  watchEffect(() => {
    try {
      storage = createInstance({
        name: "generic-store",
        storeName: locale.value,
      });
    } catch (e) {
      //
    }
  });

  const contextBase: {
    [P in keyof Generic]: GenericStateReturn<Generic[P][]>;
  } = {
    languages: useGenericState("languages"),
    nationalities: useGenericState("nationalities"),
    countries: useGenericState("countries"),
    "available-countries": useGenericState("countries?filter[available]=true"),
    regions: useGenericState("regions"),
    offers: useGenericState("offers"),
    "activity-sectors": useGenericState("activity-sectors"),
    "language-proficiencies": useGenericState("language-proficiencies"),
    "study-levels": useGenericState(
      "study-levels",
      undefined,
      false,
      studyParams
    ),
    "mission-count": useGenericState("mission-count"),
    "driver-license-lvl": useGenericState("driver-license-lvl"),
    "clothes-sizes": useGenericState("clothes-sizes"),
    "shoe-sizes": useGenericState("shoe-sizes"),
    "met-us": useGenericState("met-us"),
    agencies: useGenericState("agencies", "accounts", false),
  };

  const contextJobs: {
    [P in keyof JobsGeneric]: GenericStateReturn<JobsGeneric[P][]>;
  } = {
    "job-categories": useGenericState("categories", "job-offers"),
    "job-advantages": useGenericState("advantages", "job-offers"),
    "job-works": useGenericState("works", "job-offers"),
    "job-contract-types": useGenericState("contract-types", "job-offers"),
  };

  const context = {
    ...contextBase,
    ...contextJobs,
  };

  const loading = computed(
    () => !!Object.values(context).find((value) => value.loading.value)
  );

  async function fetch(...names: Array<keyof typeof context>) {
    for (const name of names) {
      await context[name].fetch();
    }
  }

  function refetchAll() {
    Object.values(context).forEach((value) => {
      if (value.data.value) {
        value.fetch(true);
      }
    });
  }

  const getRegionsByCountry = useMemoize(async (country: string) => {
    const storageData = await storage
      ?.getItem<{
        createdAt: number;
        data: Region[];
      }>(`regions-${country}`)
      .catch(() => {
        //
      });
    let _data: Region[] | undefined = storageData?.data;

    if (!_data) {
      _data = await GenericService.fetchGeneric<Region[]>(
        "regions",
        "generics",
        {
          "filter[country]": country,
        }
      );

      storage
        ?.setItem(`regions-${country}`, {
          createdAt: Date.now(),
          data: _data,
        })
        .catch(() => {
          //
        });
    }

    return _data;
  });

  return {
    ...context,
    getRegionsByCountry,
    loading,
    refetchAll,
    fetch,
  };
});
